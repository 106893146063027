<script setup lang="ts">
import { useWebAppHapticFeedback,useWebAppNavigation } from 'vue-tg'

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const {
  customRouterState,
  navigateToPage
} = useCustomRouter()
const { openLink } = useWebAppNavigation()

const isExplode = ref(false)
const handleClick = (pageTo: string) => {
  useWebAppHapticFeedback()
  isExplode.value = true

  setTimeout(() => {
    isExplode.value = false
    if (pageTo === 'arcademain') {
      navigateToPage('arcademain')
    }
    if (pageTo === 'treasure') {
      navigateToPage('treasure')
    }
    if (pageTo === 'memerush') {
      openLink('https://pennywhale.io/pepewhale')
    }
    if (pageTo === 'hashmining') {
      navigateToPage('hashmining')
    }
    if (pageTo === 'profile') {
      navigateToPage('profile')
    }
    if (pageTo === 'airdrop') {
      navigateToPage('airdrop')
    }
  }, 900);
  
}

const centerScroll = () => {
        const scrollContainer = document.querySelector('.overflow-x-auto');
      if (scrollContainer) {
        const centerX = scrollContainer.scrollWidth / 4;
        scrollContainer.scrollLeft = centerX;
      }
};

onMounted(() => {
    centerScroll();
});

</script>

<template>
    <div class="overflow-x-auto w-full h-screen relative">
      <div 
        v-if="isExplode"
        class="absolute bg-white opacity-30 blur-effect z-30 w-[200vw]"
      ></div>
      <div
        class="bg-[url('public/images/pennyworld/ocean.png')] bg-no-repeat bg-cover bg-center w-[200vw] h-screen relative z-0"
        :class="{ blureffect: isExplode }"
      >
        <img v-show="!isExplode"
          src="public/images/pennyworld/PennyWhaleWorld_title_all.png"
          alt="Pennyworld title" 
          class="fixed top-10 left-1/2 transform -translate-x-1/2 z-30"
        />
        <img
          src="public/images/pennyworld/PWW_MemeRush.png"
          alt="Memerush" @click="handleClick('memerush')"
          class="w-28 absolute top-1/3 right-1/4 transform -translate-x-4 -translate-y-1/2 z-20"
          
        />
        <!-- <img
          src="public/images/pennyworld/PWW_Arcade.png"
          alt="Arcade"
          @click="handleClick('arcademain')"
          class="w-40 absolute top-1/2 left-1/4 transform -translate-x-1/4 -translate-y-20 z-20"
          
        /> -->
        <img
          src="public/images/pennyworld/island2-removebg.png"
          alt="Arcade"
          class="w-28 absolute top-1/4 left-1/3 transform translate-x-6 -translate-y-2 z-20"
          
        />
        <img
          src="public/images/pennyworld/PWW_Funfair.png"
          alt="Funfair"
          @click="handleClick('treasure')"
          class="w-40 absolute top-1/2 right-1/4 transform translate-x-1/2 -translate-y-1/2"
        />
        <img
          src="public/images/pennyworld/PWW_Mining_pool.png"
          alt="hashmining"
          @click="handleClick('hashmining')"
          class="w-52 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/4 mt-20"
        />
        <img
          src="public/images/pennyworld/PWW_Communities.png"
          alt="community"
          @click="handleClick('profile')"
          class="w-48 absolute top-1/3 left-1/2 transform translate-y-1/4 -translate-x-1/2 z-20"
          
        />
        <img
          src="public/images/pennyworld/PWW_Collection.png"
          alt="wallet"
          @click="handleClick('airdrop')"
          class="w-32 absolute top-1/2 left-1/4 transform -translate-x-1/4 -translate-y-20 z-20"
        />

        <img
          src="/public/images/pennyworld/island1-removebg.png"
          alt="upper middle island"
          class="w-24 absolute top-1/3 left-1/4 transform -translate-x-12 -translate-y-1/2 z-20"
        />

        <!-- empty island section -->
        <img
          src="/public/images/pennyworld/island1-removebg.png"
          alt=""
          class="w-20 absolute top-1/4 left-1/2 transform  -translate-y-10 skew-x-12 z-20"
        />
        <img
          src="/public/images/pennyworld/island5-removebg.png"
          alt=""
          class="w-14 absolute top-1/4 left-1/2 transform translate-x-24 -translate-y-16 skew-x-12"
        />
        <img
          src="/public/images/pennyworld/island2-removebg.png"
          alt=""
          class="w-16 absolute top-1/4 left-1/4 transform -translate-y-16 translate-x-12 z-20 skew-x-12"
        />
        <img
          src="/public/images/pennyworld/island4-removebg.png"
          alt="bottom right island"
          class="w-32 absolute bottom-1/4 right-1/4 transform translate-x-40 -translate-y-1/2 z-20"
        />
        <img
          src="/public/images/pennyworld/island1-removebg.png"
          alt=""
          class="w-28 absolute bottom-1/2 right-1/4 transform translate-x-40 -translate-y-1/2 z-20"
        />
        <img
          src="/public/images/pennyworld/island5-removebg.png"
          alt=""
          class="w-12 absolute top-1/4 right-1/4 transform translate-x-40  z-20"
        />
        <img
          src="/public/images/pennyworld/island5-removebg.png"
          alt=""
          class="w-12 absolute top-1/4 right-1/3 transform translate-x-24 -translate-y-10  z-20"
        />
        <img
          src="/public/images/pennyworld/island6-removebg.png"
          alt="bottom left island"
          class="w-40 absolute bottom-1/4 left-1/4 transform -translate-x-40 -translate-y-1/4 z-20"
        />
        <img
          src="/public/images/pennyworld/island4-removebg.png"
          alt="middle left island"
          class="w-24 absolute bottom-1/2 left-1/4 transform -translate-x-32 -translate-y-1/2 z-20"
        />
        <img
          src="/public/images/pennyworld/island4-removebg.png"
          alt="upper middle left island"
          class="w-20 absolute top-1/3 left-1/4 transform -translate-x-56 -translate-y-1/2 z-20"
        />
        <img
          src="/public/images/pennyworld/island5-removebg.png"
          alt="upper left island"
          class="w-16 absolute top-1/4 left-1/4 transform -translate-x-44 -translate-y-1/2 z-20"
        />
        <img
          src="/public/images/pennyworld/island2-removebg.png"
          alt=" left island"
          class="w-16 absolute top-1/4 left-1/4 transform -translate-x-20 -translate-y-1/4"
        />
        <img
          src="/public/images/pennyworld/island6-removebg.png"
          alt=""
          class="w-10 absolute top-1/4 left-1/4 transform -translate-x-20 -translate-y-16"
        />
      </div>

      
    </div>
  </template>


<style scoped>

.blureffect {
  animation: blur-in 3.5s;
}

@keyframes blur-in {
  0% {
    backdrop-filter: blur(0px);
    
  }
  50% {
    backdrop-filter: blur(10px);
    filter: blur(15px);
  }
  100% {
    filter: blur(0px);
  }
}
</style>
<script setup lang="ts">
import useToast from '@/composables/useToast'
const { addNewSuccessToast, addNewErrorToast } = useToast();
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const {
    customRouterState,
    navigateToPage
} = useCustomRouter()
const { updateResetRedotPay } = authStore;
const isShowConfirmPopup = ref(false)
const isShowWithdrawPopup = ref(false)
const withdrawAmount = ref()
const withdrawTo = ref("")
const emit = defineEmits(["toggleWithdraw"]);

const withdrawWalletsubmitReqWithdrawal = async () => {
    if (withdrawAmount.value < 5) {
        addNewErrorToast('Please enter the right amount', "error")
        return
    }
    const response = await $fetch(`${authStore.apiUrl}/api/penny-whale/webapp/wallet/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-API-KEY": authStore.apiKey,
        },
        body: {
            dataToValidate: authState.value.authString,
            usdtAmount: withdrawAmount.value,
        },
        ignoreResponseError: true,
    });
    if (response.statusCode === 200) {
        isShowWithdrawPopup.value = false
        addNewSuccessToast(response?.message?.en, "success")
    }
    else {
        console.log(response)
        return
    }
};

const handleClose = () =>{
    emit("toggleWithdraw")
    console.log('emitted')
}

const handleWithdraw = () =>{
    if(withdrawAmount.value >= 10){
        isShowConfirmPopup.value = true
    }
    
}

</script>

<template>
    <div>
        <div class=" text-black h-screen z-10 ">
            <div class="fixed inset-0 flex items-center justify-center mx-4 ">
                <div class=" bg-slate-200 h-[75vh] w-full relative flex flex-col px-4 py-4 text-base rounded-lg">
                    <p class="text-lg ">Withdraw wallet</p>
                    <div class="flex flex-col gap-2 overflow-y-auto pt-8">
                        <div class="text-start">
                            <label class="block text-gray-700 font-bold mb-2 mx-2" for="address">
                                Address
                            </label>
                            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                v-model="withdrawTo" type="text" placeholder="Address">
                            <div v-if="withdrawTo === ''" class="text-red-500 text-xs pl-2">*Address is required</div>
                        </div>
                        <div class="text-start pt-2">
                            <label class="block text-gray-700 font-bold mb-2 mx-2" for="address">
                                Network
                            </label>
                            <input class="shadow border rounded w-full py-2 px-3 text-gray-700" id="address" type="text"
                                placeholder="BSC" disabled>
                        </div>
                        <div class="text-start pt-2">
                            <label class="block text-gray-700 font-bold mb-2 mx-2" for="address">
                                Withdrawal Amount
                            </label>
                            <input class="shadow border rounded w-full py-2 px-3 text-gray-700" v-model="withdrawAmount"
                                type="number" placeholder="Minimum 10 USDT">
                            <div v-if="withdrawAmount < 10" class="text-red-500 text-xs pl-2">*Minimum 10 USDT</div>
                            <p class="text-gray-500 pl-2 text-sm">Available {{ authState.usdtBalance }} USDT</p>
                        </div>
                        <!-- <div class="flex items-center justify-center gap-1">
                            <p class="text-left text-[#000080]">
                                Account balance : {{ authState.usdtBalance }} USD
                            </p>
                        </div> -->
                        <hr class="h-px mt-3 border-0 bg-gray-400">
                        <div class="flex flex-col text-start gap-1 mt-2">
                            <p class="text-gray-500">*Minimum withdrawal amount is 10 USDT</p>
                            <p class="text-gray-500">*Fee is 1 USDT</p>
                        </div>

                    </div>
                    <div class="flex flex-row items-center justify-center gap-4 pt-10">
                        <button @click="handleClose"
                            class="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-6 rounded text-lg">Cancel</button>
                        <button @click="handleWithdraw"  :disabled="withdrawTo === '' || withdrawAmount < 10"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded text-lg">Withdraw</button>
                    </div>
                </div>
            </div>
            <div v-if="isShowConfirmPopup" class="fixed inset-0 bg-black opacity-70" />
            <div v-if="isShowConfirmPopup" class="fixed inset-0 flex items-center justify-center h-auto mx-10 ">
                <div
                    class=" bg-slate-100 relative flex flex-col items-center justify-center px-4 py-2 text-base border-2 border-white rounded-lg">
                    <div class="flex items-center justify-center">
                        <p class="mb-5 text-center text-[#000080] text-md your-selector">
                            Confirm withdrawal <br> Are you sure you want to withdraw <br>{{ withdrawAmount}} USDT
                        </p>
                    </div>
                    <div class="flex flex-row items-center justify-center gap-1">
                        <button @click="isShowConfirmPopup=false"
                            class="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-6 rounded text-lg">Cancel</button>
                        <button @click=""
                            class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded text-lg">Confirm</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

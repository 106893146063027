const useCustomRouter = () => {
  //const page = ref('treasure');
  const page = ref('pennyworld');
  const historyStack = useState<string[]>('historyStack', () => [])
  const customRouterState = useState('CustomRouterState', () => page.value)

  const route = useRoute()

  if (route.path === '/pennywhalestma/admin/withdraw/request/withdrawList') {
    customRouterState.value = 'withdrawalrequest'
  }

  if (route.path === '/pennywhalestma/admin/taskprogress/taskcheck') {
    customRouterState.value = 'taskapproval'
  }
  const navigateToPage = (page: 'treasure' | 'friend' | 'airdrop' | 'earn' | 'mine' | 'booster' | 'monopoly' | 'leaderboard' | 'campaignrule' | 'guessbitcoin' |'guessleaderboard' | 'redotpay' | 'mainguessbtc' | 'shellpackage' | 'taskairdrop' | 'guesscampaignrule' | 'pennyworld'| 'flipcardgame'|'hashmining'|'buyhash'|'arcademain' | 'arcaderoom'|'profile' | 'pioneerpackage'|'withdraw') => {
    historyStack.value.push(customRouterState.value)
    // console.log(historyStack.value)
    customRouterState.value = page
  }

  const goBack = () => {
    if (historyStack.value) {
      if (historyStack.value[historyStack.value.length - 1] === 'pennyworld') {
        customRouterState.value = 'pennyworld'
        historyStack.value = [];
      } else {
        customRouterState.value = historyStack.value.pop() || 'pennyworld';
      }
    } else {
      console.warn('No previous state to go back to')
    }
  }

  return {
    customRouterState,
    navigateToPage,
    goBack
  }
}

export default useCustomRouter